<template>
    <div class="mr-2">

        <div @mouseleave="show_dropdown = false" class="dropdown is-right is-active">
            <div class="dropdown-trigger">
                <button @mouseenter="show_dropdown = true" class="button is-small pl-0" aria-haspopup="true" aria-controls="dropdown-menu">
                    <country-flag :country="$i18n.locale == 'en' ? 'gb': $i18n.locale" />
                    <!-- <img :src="'/flags/'+ $i18n.locale +'.png'"> -->
                    <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
            </div>
            <div v-if="show_dropdown" class="dropdown-menu" id="dropdown-menu" role="menu" style="min-width:auto;">
                <div class="dropdown-content">
                    <a v-if="$i18n.locale != 'de'" @click.prevent="change_language('de')" class="dropdown-item is-flex p-1">
                        <!-- <img :src="'/flags/de.png'"> -->
                        <country-flag country='de' size='normal'/>
                    </a>
                    <a v-if="$i18n.locale != 'en'" @click.prevent="change_language('en')" href="#" class="dropdown-item is-flex p-1">
                        <!-- <img :src="'/flags/en.png'">  -->
                        <country-flag country='gb' size='normal'/>
                    </a>
                    <a v-if="$i18n.locale != 'it'" @click.prevent="change_language('it')" class="dropdown-item is-flex p-1">
                        <!-- <img :src="'/flags/de.png'"> -->
                        <country-flag country='it' size='normal'/>
                    </a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import CountryFlag from 'vue-country-flag';
export default {
    data() {
        return {
            show_dropdown: false
        }
    },
    components:{
        CountryFlag
    },
    methods: {
        change_language(lang){
            localStorage.setItem('lang', lang)
            this.$i18n.locale = localStorage.lang
            this.show_dropdown = false
            this.$parent.$parent.app_lang = localStorage.lang
            this.$moment.locale(this.$i18n.locale)

        }
    },

}
</script>

<style>
.normal-flag{ 
	margin:0 !important;
}
</style>
